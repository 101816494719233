<template>
  <div class="member">
    <van-nav-bar
      title="转让大厅"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="search_main">
      <van-sticky :offset-top="0">
        <van-search
          v-model="keywords"
          placeholder="请输入搜索标题/价格"
          show-action
          shape="round"
          @search="onSearch"
          @cancel="onCancel"
        >
          <template #action>
            <div style="display: flex;">
              <div @click="onSearch" style="padding: 0 10px;margin-right: 15px;">搜索</div>
              <van-button size="small" @click="zhuanform" type="warning">发布转让</van-button>
            </div>
          </template>
        </van-search>
      </van-sticky>
    </div>
    <div class="list_main">
      <van-pull-refresh style="min-height: 100vh;" v-model="isRefresh" success-text="刷新完成" @refresh="onRefresh">
        <van-list
          v-model="noloading"
          :finished="nofinished"
          :finished-text="finishedText"
        >
          <div v-if="!isnodata">
            <van-cell center v-for="(item, index) in data" :key="index">
              <template #default>
                <div class="list_title">
                  <div class="list_title_text">{{item.title}}</div>
                </div>
                <div class="level_list">
                  <div style="color: #ee0a24; margin-right: 10px;">¥<span style="font-size: 24px;">{{item.price}}</span></div>
                  <van-tag type="primary" round>最多可够买 {{item.buymax}} 份</van-tag>
                </div>
              </template>
              <template #right-icon>
                <div>
                  <div style="text-align: right;">
                    <van-button type="danger" size="mini" plain round @click="buy(item.id)">购买</van-button>
                  </div>
                </div>
              </template>
            </van-cell>
          </div>
          <van-empty v-if="isnodata" description="暂无数据" />
        </van-list>
      </van-pull-refresh>
    </div>

    <van-dialog
      v-model="showzhuan"
      title="购买活跃分" 
      show-cancel-button
      :before-close="doBuy"
    >

      <div class="buyform">
        <van-field
          v-model="buynum"
          label-width="70px"
          label="购买数量："
        />
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  name: "Member",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      keywords: "", // 搜索关键词
      data: [],
      currOffset: 0,
      currSize: 20,
      
      finishedText: "",
      loading: false,
      finished: false,
      isLoading: false,
      isRefresh: false, 
      noloading: false,
      nofinished: false,
      isnodata: false,
      nodata: false,

      zhuanid: 0,
      buynum: '',
      showzhuan: false,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getInfo();
    },

    onRefresh() {
      this.currOffset = 0;
      this.isnodata = this.nodata = false;
      this.getInfo();
    },

    getInfo() {
      let that = null;
      that = this;
      that.$toast({
        message: '加载中...',
        duration: 0,
        forbidClick: true,
        overlay: true
      })
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "team/zhuan/list_credit3",
          that.$qs.stringify({
            isuser: 0,
            offset: that.currOffset,
            size: that.currSize,
            keywords: that.keywords,
          })
        )
        .then(res => {
          that.$toast.clear();
          if (that.keywords) {
            that.data = [];
          }
          that.loading = false;
          that.isLoading = false;
          if (res.data.code == 100000) {
            that.nodata = false;
            if (that.currOffset == 0) {
              that.data = res.data.data;
            }else {
              res.data.data.forEach(item => {
                that.data.push(item);
              })
            }
            if (res.data.data.length >= that.currSize) {
              that.currOffset += that.currSize;
              that.finished = false;
            }else {
              that.currOffset += res.data.data.length;
              that.finished = true;
              that.finishedText = "没有更多了";
            }
          }else {
            that.finished = true;
            if (that.keywords && that.keywords.length > 0) {
              that.nodata = true;
              that.finishedText = "";
            }else {
              if(that.currOffset == 0) {
                that.nodata = true;
                that.finishedText = "";
              }else{
                that.finishedText = "没有更多了";
              }
            }
          }

          that.isRefresh = that.isLoading, 
          that.noloading = that.loading,
          that.nofinished = that.finished,
          that.isnodata = that.nodata;
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 执行搜索
    onSearch() {
      console.log(this.keywords);
      this.currOffset = 0;
      this.getInfo();
    },
    // 取消搜索
    onCancel() {
      console.log(this.keywords);
      this.currOffset = 0;
      this.finished = false;
      this.nodata = false;
      this.data = [];
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },

    // 购买
    buy(id) {
      let _that = null;
      _that = this;
      _that.zhuanid = id;
      _that.buynum = '';
      _that.showzhuan = true;
    },
    // 确认购买
    doBuy(action, done) {
      let that = null;
      that = this;
      if(action == 'confirm'){
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "team/zhuan/buy_credit3",
            that.$qs.stringify({
              zhuanid: that.zhuanid,
              buynum: that.buynum
            })
          )
          .then(res => {
            if (res.data.code == 100000) {
              that.$toast(res.data.msg ? res.data.msg : "购买成功");
              setTimeout(() => {
                that.onRefresh();
              }, 1500);
              done();
            }else {
              that.$toast(res.data.msg ? res.data.msg : "购买失败");
              done(false);
            }
          })
          .catch(err => {
            done(false);
          })
      }else {
        done();
      }
    },

    // 发布转让
    zhuanform(){
      this.$router.push({
        name: "Zhuanform",
        query: {
          t: new Date().getTime()
        }
      })
    }

  },
}
</script>
<style lang="less">
.member {
  width: 100%;
  min-height: 100%;
  background-color: #f0f0f0;
  &:last-child {
    padding-bottom: 50px;
  }
  .list_main {
    width: 100%;
    overflow: hidden;
    padding-bottom: 50px;
    .van-cell {
      .van-cell__title {
        padding: 0 10px 0 0;
        overflow: hidden;
        position: relative;
        .van-image {
          display: block;
        }
        .list_fen_tag {
          .van-tag {
            margin-right: 5px;
            padding: 2px 4px;
            position: absolute;
            right: 5px;
            bottom: 0;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
      .van-cell__value {
        flex: 1;
        text-align: left;
        .list_title {
          display: flex;
          overflow: hidden;
          .list_title_text {
            overflow: hidden;
            color: #000;
          }
          .list_title_tag {
            min-width: 118px;
            flex: 1;
            margin: 0 10px;
            display: flex;
            align-items: center;
            .tag_agent {
              padding: 0 8px;
              height: 20px;
              line-height: 20px;
            }
          }
        }
        .level_list {
          margin-top: 5px;
          display: flex;
          justify-items: center;
          line-height: 18px;
          .van-tag {
            padding: 1px 5px;
            margin-right: 5px;
            &:last-of-type {
              margin-right: 0px;
            }
          }
        }
      }
      .van-button {
        padding: 0 10px;
        font-size: 13px;
      }
    }
  }

  .van-action-sheet__header {
    color: #999;
    padding-bottom: 20px;
  }
  .van-action-sheet__content {
    padding: 0 10px;
    .levelTan_list {
      border-bottom: 1px solid #eee;
      &:last-of-type {
        border-bottom: 0;
      }
    }
  }
  
}
</style>